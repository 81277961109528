.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}

.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content, .ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content{
  display: flex;
  align-items: center;
}

.membershipPlanTableSearchFilter{
  display: flex;
}

.white-card{
  background: #FFFFFF;
  border: 1px solid #E6EBF1;
  border-radius: 12px;
}

.membershipPaymentPending{
  background: #FFE3E8;
  width: fit-content;
  padding: 0px 10px;
  border-radius: 6px;
  text-align: "center";
  font-size: 12px;
}
.membershipPaymentPaid{
  background-color: #EDFFF9;
  width: fit-content;
  padding: 0px 10px;
  border-radius: 6px;
  text-align: "center";
  font-size: 12px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.ant-layout-header{
  z-index:1000!important;
}
.ant-drawer-content{
  z-index:100001!important;
}
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
 }
/* Track */
 ::-webkit-scrollbar-track {
   background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
   background: #bec4c4;
}

/* Handle on hover */
 ::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.apexcharts-datalabel , .apexcharts-toolbar{
  display: none !important;
}

.ant-picker-dropdown {
  z-index: 100001 !important;
}
.app-header{
  position: fixed;
  z-index: 100!important;
}


.profile-picture-add .ant-upload.ant-upload-select-picture-card{
  height:100px !important;
}

.rejectClassContainer{
  border-radius: 6px;
background: rgba(211, 18, 18, 0.267) !important;
display: inline-flex;
height: 20px;
padding: 8px;
align-items: center;
gap: 10px;
flex-shrink: 0;

}
